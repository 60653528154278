interface Environment {
	build_env: string;
	fed_env: string;
}
interface Window {
	initializeRemote: (
		remoteKey: string,
		webpackEnvironment: Environment,
		overrideUrl?: string,
	) => Promise<void>;
	webpackEnvironment: Environment;
}

const getRegion = (
	cloudOsRemotes: string[],
	remoteKey: string,
): string | undefined => {
	if (!cloudOsRemotes.includes(remoteKey)) return;

	// Only cloudos remotes have region
	if (window.origin.includes('-eu')) return 'eu';
	if (window.origin.includes('-ap')) return 'ap';

	return;
};

window.initializeRemote = async (
	remoteKey: string,
	webpackEnvironment: Environment,
	overrideUrl?: string,
) => {
	const { getRemote, getFederatedEnv, REMOTES } = await import(
		'@innovyze/lib_get_remote'
	);
	const cloudOsRemotes = [REMOTES.TOOLS, REMOTES.DASHBOARD];
	let url = overrideUrl;

	const region = getRegion(cloudOsRemotes, remoteKey);
	if (!window.webpackEnvironment && webpackEnvironment) {
		window.webpackEnvironment = webpackEnvironment;
	}

	if (!url) {
		// @ts-expect-error unknown remoteKey type
		const federatedEnv = getFederatedEnv(window.webpackEnvironment);
		const remote = getRemote(
			// @ts-expect-error unknown remoteKey type
			remoteKey,
			federatedEnv,
			region,
		);
		const [remoteReference] = Object.values(remote);

		url = overrideUrl || remoteReference.split('@')[1];
	}

	return new Promise(resolve => {
		const script = document.createElement('script');

		script.src = url + '?ts=' + Date.now();
		script.async = true;
		script.onload = () => {
			// @ts-expect-error unknown key type
			resolve(window[remoteKey]);
		};
		document.head.appendChild(script);
	});
};

/* eslint-disable @typescript-eslint/ban-ts-ignore */
//@ts-ignore
import('./index');
